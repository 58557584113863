<template>
    <div class="orderShareRecord-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">增值服务</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">分润收益记录</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="分润获得时间" prop="orderShareTimeRanges">
                <el-date-picker
                    size="mini"
                    v-model="queryModel.orderShareTimeRanges"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="订单编号" prop="outOrderNo">
                <el-input v-model="queryModel.outOrderNo" size="mini"/>
            </el-form-item>
            <el-form-item label="商品类型" prop="typeId">
                <el-select v-model="queryModel.typeId" placeholder="请选择" size="mini">
                <el-option
                    v-for="item in goodTypeList"
                    :key="item.id"
                    :label="item.goodsName"
                    :value="item.id"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属学校" prop="schoolId">
                <el-select v-model="queryModel.schoolId" placeholder="请选择" size="mini">
                <el-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单完成时间" prop="orderCompletedTimeRanges">
                <el-date-picker
                    size="mini"
                    v-model="queryModel.orderCompletedTimeRanges"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
        >导出数据</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column prop="orderShareTime" label="分润获得时间" width="200"></el-table-column>
            <el-table-column prop="platformRevenueFee" label="平台收益" width="100"></el-table-column>
            <el-table-column prop="merchantRevenueFee" label="商家收益" width="100"></el-table-column>
            <el-table-column prop="salesRevenueFee" label="销售代表分润金额" width="100"></el-table-column>
            <el-table-column prop="outOrderNo" label="关联订单" ></el-table-column>
            <el-table-column prop="payFee" label="订单金额" width="100"></el-table-column>
            <el-table-column prop="goodsType" label="商品类型" ></el-table-column>
            <el-table-column prop="schoolName" label="所属学校" ></el-table-column>
            <el-table-column prop="orderCompletedTime" label="订单完成时间" ></el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>

    </div>
</template>
<script>
import orderShareRecordApi from '@/api/base/orderShareRecord'
import detentionWarningApi from '@/api/bus/detentionWarning'
import goodsInfoApi from "@/api/base/goodsInfo";
export default {
    name:"baseOrderShareRecordList",
        data(){
            return{
                queryModel:{
                    orderShareTimeRanges:"",
                    outOrderNo:"",
                    typeId:"",
                    schoolId:"",
                    orderCompletedTimeRanges:"",
                },
                goodTypeList:[],
                schoolList:[],
                loading: false,
                tableData: [],
                pageIndex: 1,
                pageSize: 10,
                totalElements: 0,
                pageSizeList: [10,20,30],
                showModal: false,
                downloadLoading: false,
            }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);

            formData.append("orderShareTimeRanges", this.queryModel.orderShareTimeRanges);
            formData.append("outOrderNo", this.queryModel.outOrderNo);
            formData.append("typeId", this.queryModel.typeId);
            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("orderCompletedTimeRanges", this.queryModel.orderCompletedTimeRanges);

            orderShareRecordApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        downloadXls() {
            //导出
            var self = this;
            self.downloadLoading = true;
            self.loading = true;
            self.loadingText = "数据导出中，请稍后...";

            var formData = new FormData();
            formData.append("orderShareTimeRanges", this.queryModel.orderShareTimeRanges);
            formData.append("outOrderNo", this.queryModel.outOrderNo);
            formData.append("typeId", this.queryModel.typeId);
            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("orderCompletedTimeRanges", this.queryModel.orderCompletedTimeRanges);

            formData.append("pageSize", 2000);

            orderShareRecordApi.exportXls(formData).then(function(response) {
                var jsonData = response.data;
                self.downloadLoading = false;
                self.loading = false;
                if (jsonData.result) {
                //下载有错误信息提示的报表
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                    duration: 60000,
                    type: "success"
                });
                } else {
                self.$message.error(jsonData.message);
                }
            });
        },
    },
    created() {
        var self = this;

        goodsInfoApi.goodsTypeList().then(response => {
            var jsonData = response.data;
            this.goodTypeList = jsonData.data;
        });

        detentionWarningApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    mounted() {
        this.changePage(1);
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.orderShareRecord-list{
    text-align: left;
}
</style>