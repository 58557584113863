import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
    return request.post(constant.serverUrl + "/base/order/orderShareRecordList", formData);
}


function exportXls(formData) {
    //导出xls
    return request.post(constant.serverUrl + "/base/order/exportXlsOrderShareRecord", formData);
}

export default {
    pageList,exportXls
}